import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyinsight.scss';

import imgQuote from "../images/quotation_mark.webp";

import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFull extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundSize: 'cover',
            height: this.props.height,
            backgroundPosition: '50% 50%'
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-three-insight-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width="212" height="52" alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2024/01/merit-mile-insight-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div class="container detail-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="first-paragraph">
                            <p>In uncertain times, data provides certainty. It helps enterprises navigate the rapidly morphing economic and business landscapes. By placing data at the center of the decision-making process, businesses can quickly unlock powerful insights to adapt to swiftly changing priorities, retool budgets, prioritize productivity, and continue to invest for growth.</p>
                            <div class="row">
                                <div class="col-md-10">
                                    <p>To address this growing need, Microsoft released Azure Synapse, a cloud-based analytics service that unifies enterprise data warehousing and big data analytics at cloud-scale. Together with Insight, a Gold partner and global provider of technology and cloud solutions, they set out to combine their expertise to help businesses create a modernized data warehousing strategy that supports limitless scale and accelerates business operations.</p>
                                </div>
                                <div class="col-md-2">
                                    <p><img src="https://www.meritmile.com/uploads/2020/08/azure-synapse-analytics-logo.png" alt="Azure Synapse Analytics" style={{width: '100%', height: 'auto', maxWidth: '250px'}} /></p>
                                </div>
                            </div>
                            <p>Their partner marketing teams approached Merit Mile to develop a hybrid content marketing and account based marketing (ABM) campaign to generate awareness and demand from a specific set of decision makers for the joint analytics solution.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container single-image-text-wrapper solutions detail-wrapper">
                <div class="row">
                    <div class="col-md-7 order-last images d-flex align-items-end justify-content-start flex-column">           
                        <img src="https://www.meritmile.com/uploads/2020/08/insight-solutions-1@2x.png" class="img-fluid" width="539" height="278" alt="" />
                        <p>eBook</p>
                        <img src="https://www.meritmile.com/uploads/2020/08/insight-solutions-2@2x.png" class="img-fluid" width="536" height="466" alt="" />
                        <p>Solution overview</p>
                        <img src="https://www.meritmile.com/uploads/2020/08/insight-solutions-3@2x.png" class="img-fluid" width="536" height="133" alt="" />
                        <p>Social media images</p>
                    </div>
                    <div class="col-md-5 text">
                        <div class="text">
                            <h3>Solution</h3>
                            <p>Data modernization and cloud-scale analytics is the new go-to strategy for turning massive datasets into actionable insights and tangible business value. By using Azure Synapse to unify data from multiple platforms, businesses can gather powerful insights and uncover the answers to their most critical questions at blazing speed.</p>
                            <p>As such, Merit Mile developed a demand generation campaign with heavily targeted messaging around data modernization to a specific audience of data scientists, directors of analytics, data engineers, and key business decision makers. Using an engaging overview video and eBook, we highlighted how Azure Synapse enables businesses to leverage data faster and easier so they can -</p>
                            <ul>
                                <li>Gain clarity and make breakthrough discoveries</li>
                                <li>Improve customer engagement and relationships</li>
                                <li>Optimize spending and be more productive</li>
                                <li>Stay ahead of change and speed up innovation</li>
                                <li>Accelerate path to value</li>
                            </ul>
                            <p>We calibrated our campaign by defining the ideal target customer profile and accounts and shaped our content marketing and ABM tactics based on buyer activities and current business conditions. By identifying targeted customer profiles and accounts to define our demand generation tactics, our content syndication platform's recommendation engine was able to promote relevant content to prospects based on their interests and recent activity around data analytics and related topics.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container results detail-wrapper">
                <div class="row">
                    <div class="col-12 text">
                        <div class="text">
                            <h3>Results</h3>
                            <p>Using precision targeted content syndication platforms, our demand generation strategy <strong>generated 209 marketing qualified leads</strong> in less than five weeks from numerous industries, spanning medium-sized organizations to firms in the <em>Fortune</em> 100. And because the joint solution was properly positioned and the messaging was hyper-targeted to a specific audience, the leads were captured at acquisition costs below the conventional cost per lead (CPL) price of enterprise B2B thresholds.</p>
                            <p>For more information or to schedule a virtual meeting with a Merit Mile marketing associate, please <a href="mailto:hello@meritmile.com?subject=Insight 2020 Case Study Inquiry">email us here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container single-image-text-wrapper deliverables detail-wrapper">
                <div class="row">
                    <div class="col-md-8 order-last d-flex justify-content-center align-items-end">
                        <img src="https://www.meritmile.com/uploads/2020/08/insight-results@2x.png" class="img-fluid" width="0" height="0" alt="" />
                    </div>
                    <div class="col-md-4 text">
                        <div class="text">
                            <h3>Deliverables</h3>
                            <ul>
                                <li>eBook</li>
                                <li>Video</li>
                                <li>Solution overview</li>
                                <li>209 marketing qualified leads (MQLs)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)